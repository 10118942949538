<template>
<div class="area">
  <!-- <img  v-if="value===100" :src="check" alt="" class="check">
  <CircleProgress v-if="value<100" class="circle" :is-gradient="true"
    :gradient="{
        angle: 90,
        startColor: 'rgba(209, 252, 202, 1)',
        stopColor: 'rgba(173, 233, 246, 1)'
    }" :percent="radius" :viewport="true" :show-percent="true" empty-color="rgba(254, 213, 152, 0.31)"
 /> -->
  <!-- <img  v-if="value===100" :src="check" alt="" class="check"> -->
  <CircleProgress  class="circle" :is-gradient="true"
    :gradient="{
        angle: 90,
        startColor: 'rgba(209, 252, 202, 1)',
        stopColor: 'rgba(173, 233, 246, 1)'
    }" :percent="radius" :viewport="true" :show-percent="true" empty-color="rgba(254, 213, 152, 0.31)"
 />

</div>
  <!-- <div class="single-chart">
    <svg viewBox="0 0 36 36" class="circular-chart">
      <filter id="neon">
        <feFlood flood-color="rgb(255,255,128)" flood-opacity="0.5" in="SourceGraphic" />
        <feComposite operator="in" in2="SourceGraphic" />
        <feGaussianBlur stdDeviation="10" />
        <feComponentTransfer result="glow1">
          <feFuncA type="linear" slope="4" intercept="0" />
        </feComponentTransfer>
        <feMerge>
          <feMergeNode in="glow1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter> -->

      <!-- <path class="circle-bg"
            d="M18 2.0845
               a 15.9155 15.9155 0 0 1 0 31.831
               a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <path  class="circle"
            :stroke-dasharray="radius"
            d="M18 2.0845
               a 15.9155 15.9155 0 0 1 0 31.831
               a 15.9155 15.9155 0 0 1 0 -31.831"
      /> -->
      <!-- <text v-if="value" x="18" y="22" class="percentage">{{value}}%</text>
      <text v-else x="18" y="22" class="percentage">- -</text> -->
    <!-- </svg> -->
  <!-- </div> -->
</template>

<script setup>
import check from '../assets/images/check.png'
import 'vue3-circle-progress/dist/circle-progress.css'
import CircleProgress from 'vue3-circle-progress'
import { defineProps, computed } from 'vue'
const props = defineProps({
  value: {
    type: Number,
    default: 0
  }
})

const radius = computed(() => `${props.value}`)
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');
.area{
  display: flex;
  justify-content: center;
}
  .current-counter::after {
            content: "%";
        }
    
.single-chart {
  width: 130px;
}

.circle-bg {
  fill: none;
  stroke: rgba(255, 255, 255, 0.2);
  stroke-width: 3;
}

.percentage {
  fill: #fff;
  font-family: 'Roboto Condensed', Calibri, sans-serif;
  font-size: 13px;
  letter-spacing: -1px;
  text-anchor: middle;
}
.check{
    width: 150px;
    }

.circle {
 font-family: 'Roboto Condensed';
font-style: normal;
font-weight: 300;
font-size: 48px;
line-height: 120.38%;
/* or 58px */
display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.085em;
color: #FFFFFF;
}

</style>
